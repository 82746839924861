<template>
	<div class="create-store">
		<van-nav-bar
      fixed
      title="Add Channel"
      left-arrow
      @click-left="goBack">
			<!-- <span class="edit" slot="right" @click="handleSave">Save</span> -->
			<!-- <van-button class="edit" slot="right" type="info" mini block round native-type="submit">Save</van-button> -->
    </van-nav-bar>
		<div class="nav-bar"></div>
		<van-tabs v-model="tabsActive">
			<van-tab title="Basic info.">
				<van-form class="basin-info">
					<van-field
						v-model="storeForm.address"
						name="Area Positioning"
						required
						label="Area Positioning"
						class="area-position"
						placeholder=""
						@click="getAddress"
					>
					  <template #right-icon>
							<img class="icon-loc-img" src="@/assets/images/address.png" alt="">
							<van-icon size="20" name="close" @click.stop="closeLoc" />
						</template>
					</van-field>
					<van-field
						v-model="storeForm.nation"
						name="Country"
						label="Country"
						placeholder=""
					/>
					<van-field
						v-model="storeForm.province"
						name="State/Province"
						label="State/Province"
						placeholder=""
					/>
					<van-field
						v-model="storeForm.city"
						name="City"
						label="City"
						placeholder=""
					/>
					<van-field
						v-model="storeForm.zipCode"
						name="Postal Code"
						label="Postal Code"
						placeholder=""
					/>
					<van-field
						v-model="storeForm.customerName"
						name="Store Name"
						label="Store Name"
						required
						style="font-weight: bold;"
						placeholder=""
					/>
					<van-field name="uploader" label="Store Pictures">
						<template #input>
							<van-uploader v-model="storeForm.imgUrl" :after-read="(files, detail) => afterRead(files, detail, 'signOutFileList')" />
						</template>
					</van-field>
					<van-field name="storeTypeList" label="Store Type">
						<template #input>
							<van-radio-group v-model="storeForm.customerType" direction="horizontal">
								<van-radio v-for="(storeItem, storeIndex) in storeTypeList" :key="storeIndex" :name="storeItem.value">{{ storeItem.label }}</van-radio>
							</van-radio-group>
						</template>
					</van-field>
					<van-field name="customerLevel" label="Store Level">
						<template #input>
							<van-radio-group v-model="storeForm.customerLevel" direction="horizontal">
								<van-radio v-for="(levelItem, levelIndex) in levelList" :key="levelIndex" :name="levelItem.value">{{ levelItem.label }}</van-radio>
							</van-radio-group>
						</template>
					</van-field>
					<div></div>
				</van-form>
			</van-tab>
			<van-tab title="Contact">
				<van-form>
					<div v-for="(contactItem, contactIndex) in storeForm.contactNameList" :key="contactIndex" class="contact-content">
						<div class="contact-item">
							<span class="line"></span>
							<span>{{ contactItem.label }}</span>
						</div>
						<van-field
							v-model="contactItem.title"
							name="Title"
							label="Title"
							placeholder=""
						/>
						<van-field
							v-model="contactItem.contactName"
							name="Name"
							label="Name"
							placeholder=""
						/>
						<van-field
							v-model="contactItem.phone"
							name="Cell Phone Number"
							label="Cell Phone Number"
							placeholder=""
						/>
						<van-field
							v-model="contactItem.race"
							name="Race"
							label="Race"
							placeholder=""
						/>
						<van-field
							v-model="contactItem.email"
							name="E-Mail"
							label="E-Mail"
							placeholder=""
						/>
						<div></div>
					</div>
				</van-form>
			</van-tab>
			<van-tab title="Business info.">
				<van-form>
					<div class="choice-content">
						<van-field name="closeBrand" label="Disposable brand">
							<template #input>
								<van-checkbox-group v-model="storeForm.closeBrand" direction="horizontal">
									<van-checkbox v-for="(openItem, openIndex) in closeBrandList" :key="openIndex" :name="openItem.value" shape="square">{{ openItem.label }}</van-checkbox>
								</van-checkbox-group>
							</template>
						</van-field>
						<van-field name="openBrand" label="Hardware brand">
							<template #input>
								<van-checkbox-group v-model="storeForm.openBrand" direction="horizontal">
									<van-checkbox v-for="(openItem, openIndex) in openBrandList" :key="openIndex" :name="openItem.value" shape="square">{{ openItem.label }}</van-checkbox>
								</van-checkbox-group>
							</template>
						</van-field>
						<van-field name="radio" label="Member system">
							<template #input>
								<van-radio-group v-model="storeForm.memberSystem" direction="horizontal">
									<van-radio :name="1">Yes</van-radio>
									<van-radio :name="0">No</van-radio>
								</van-radio-group>
							</template>
						</van-field>
						<van-field name="radio" label="Chain or not">
							<template #input>
								<van-radio-group v-model="storeForm.isChain" direction="horizontal">
									<van-radio :name="1">Yes</van-radio>
									<van-radio :name="0">No</van-radio>
								</van-radio-group>
							</template>
						</van-field>
						<van-field
							v-if="ischainCount"
							v-model="storeForm.chainCount"
							name="Chain count"
							label="Chain count"
							type="number"
							placeholder=""
						/>
						<van-field name="restockDay" label="Purchase time">
							<template #input>
								<van-checkbox-group v-model="storeForm.restockDay" direction="horizontal">
									<van-checkbox v-for="(timeItem, timeIndex) in goodsTimeList" :key="timeIndex" :name="timeItem.value" shape="square">{{ timeItem.label }}</van-checkbox>
								</van-checkbox-group>
							</template>
						</van-field>
						<div></div>
					</div>
					<van-field
						v-model="storeForm.acreage"
						name="Store Size"
						label="Store Size(ft²)"
						placeholder=""
					/>
					<van-field
						v-model="storeForm.dailyFlow"
						name="Single Day Traffic"
						label="Single Day Traffic(p)"
						placeholder=""
					/>
					<van-field
						v-model="storeForm.dayOnceCount"
						name="Daily sales of disposable"
						label="Daily sales of disposable(pcs)"
						placeholder=""
					/>
					<van-field
						v-model="storeForm.onceAmountRate"
						name="Daily sales percentage of disposable"
						label="Daily sales percentage of disposable($)"
						placeholder=""
					/>
					<van-field
						v-model="storeForm.onceDisplayRate"
						name="Percentage of disposable display"
						label="Percentage of disposable display(%)"
						placeholder=""
					/>
					<van-field
						v-model="storeForm.wholesale"
						name="Supplier"
						label="Supplier"
						placeholder=""
					/>
					<div></div>
				</van-form>
			</van-tab>
		</van-tabs>
		<div class="btn-control">
      <van-button type="info" block  @click="handleSave">Save</van-button>
    </div>
	</div>
</template>

<script>
import { dictDetailMapApi, commonFileUploadApi, arsCustomerAdd, carsCustomerAppInfoApi } from '@/api/home'
import { Toast, Notify } from 'vant'
export default {
	data () {
		return {
			storeForm: {
				lng: '', // 经度
				lat: '', // 纬度
				customerName: '', // 名称
				imgUrl: [], // 门店图片
				contactNameList: [
					{ id: '', label: 'Contact1', title: '', contactName: '', phone: '', race: '', email: '', customerId: '' },
					{ id: '', label: 'Contact2', title: '', contactName: '', phone: '', race: '', email: '', customerId: '' },
					{ id: '', label: 'Contact3', title: '', contactName: '', phone: '', race: '', email: '', customerId: '' },
				],
				// contactName: '', // 联系人名称
				nation: '', // 国家
				province: '', // 台/州
				city: '', // 市
				address: '', // 详细地址
				zipCode: '', // 邮政编码
				customerLevel: '', // 门店级别 单选 SABCD
				customerType: '', // 店铺类型 多选 接口
				acreage: '', // 店铺面积
				dailyFlow: '', // 单日人流量
				dayOnceCount: '', // 一次性单日销售
				onceAmountRate: '', // 一次性销售额占比
				onceDisplayRate: '', // 一次性陈列占比
				wholesale: '', // 批发商
				restockDay: [], // 进货时间 （周一 至 周天）多选
				isChain: '', // 是否连锁 单选 0否 1是
				openBrand: [], // 开放式品牌 多个 多选 接口
				closeBrand: [], // 封闭式品牌 多个 多选 接口
				memberSystem: '', // 是否有会员系统 0否 1是
				chainCount: ''
			},
			storeTypeList: [],
			openBrandList: [],
			closeBrandList: [],
			goodsTimeList: [
				{ label: 'Mon.', value: 'Monday' },
				{ label: 'Tue.', value: 'Tuesday' },
				{ label: 'Wed.', value: 'Wednesday' },
				{ label: 'Thu.', value: 'Thursday' },
				{ label: 'Fri.', value: 'Friday' },
				{ label: 'Sat.', value: 'Saturday' },
				{ label: 'Sun.', value: 'Sunday' },
			],
			levelList: [
			  { label: 'S', value: 'S' },
			  { label: 'A', value: 'A' },
			  { label: 'B', value: 'B' },
			  { label: 'C', value: 'C' },
			  { label: 'D', value: 'D' },
			],
			paramsUrl: {},
			tabsActive: 0,
			ischainCount: false
		}
	},
	watch: {
		'storeForm.isChain'(newVal) {
			console.log('storeForm.isChain', newVal)
			if (newVal == 1) {
				this.ischainCount = true
			} else {
				this.ischainCount = false
			}
		}
	},
	deactivated(){
		this.$destroy();
	},
	activated() {
		console.log('activated')
		this.getDetailMap()
		this.paramsUrl = this.$route.query
		if (this.paramsUrl.tabIndex) {
			this.tabsActive = Number(this.paramsUrl.tabIndex)
		}
		if (this.paramsUrl.art_id) {
			this.editShowData()
		}
		console.log(JSON.parse(localStorage.getItem('centerPoint')))
		let centerPoint = JSON.parse(localStorage.getItem('centerPoint'))
		if (centerPoint) {
			this.storeForm.address = centerPoint.address
			this.storeForm.lng = centerPoint.lng
			this.storeForm.lat = centerPoint.lat
			if ((Object.prototype.toString.call(centerPoint.results) === '[object Array]') && centerPoint.results[0]) {
				centerPoint.results[0].address_components.forEach(subItem => {
					if (subItem.types.includes('country') && subItem.types.includes('political')) {
						this.storeForm.nation = subItem.long_name
					} else if (subItem.types.includes('administrative_area_level_1') && subItem.types.includes('political')) {
						this.storeForm.province = subItem.long_name
					} else if (subItem.types.includes('locality') && subItem.types.includes('political')) {
						this.storeForm.city = subItem.long_name
					} else if (subItem.types.includes('postal_code')) {
						this.storeForm.zipCode = subItem.long_name
					}
				})
			}
		}
	},
	methods: {
		closeLoc() {
			this.storeForm.lng = ''
			this.storeForm.lat = ''
			this.storeForm.nation = ''
			this.storeForm.province = ''
			this.storeForm.city = ''
			this.storeForm.address = ''
			this.storeForm.zipCode = ''
			localStorage.removeItem('centerPoint')
      localStorage.removeItem('lat')
      localStorage.removeItem('lng')
      localStorage.removeItem('address')
		},
		handleSave() {
			const formdata = this.storeForm
			if (!formdata.address) {
				Toast('Regional positioning is required')
				return
			}
			if (!formdata.customerName) {
				Toast('Store name is required')
				return
			}
			const imgUrl = formdata.imgUrl.map(item => {
				if (item.domain) {
					return item.domain + item.path
				} else {
					return item.url
				}
			})
			const data = {
				...formdata,
				closeBrand: formdata.closeBrand.join(','),
				imgUrl: imgUrl.join(','),
				contacts: formdata.contactNameList,
				// customerType: formdata.customerType.map(item => item).join(','),
				restockDay: formdata.restockDay.map(item => item).join(','),
				closeBrand: formdata.closeBrand.map(item => item).join(','),
				openBrand: formdata.openBrand.map(item => item).join(',')
			}
			if (data.isChain == 0) {
				data.chainCount = ''
			}
			console.log('data', data)
			Toast.loading({
        message: 'loading...',
        forbidClick: true,
        duration: 0
      })
      arsCustomerAdd(data).then(res => {
        Toast.clear()
        Notify({ type: 'success', message: 'success' })
        localStorage.removeItem('centerPoint')
        localStorage.removeItem('lat')
        localStorage.removeItem('lng')
        localStorage.removeItem('address')
        // this.$router.push('/layout')
				this.$router.go(-1)
      }).catch(() => {
        // Toast.clear()
      })
		},
		// 编辑回显值
		editShowData() {
      Toast.loading({
        message: 'loading...',
        forbidClick: true,
        duration: 0
      })
      carsCustomerAppInfoApi(this.$route.query.art_id).then(res => {
        Toast.clear()
        console.log('carsCustomerAppInfoApi', res)
				res.data.contacts.forEach((conItem, conIndex) => {
					conItem.label = `Contact${conIndex + 1}`
				})
				const result = res.data
				if (result.contacts.length < 3) {
					let contactsLen = result.contacts.length
					for (let index = 0; index < 3 - contactsLen; index++) {
						// { id: '', label: 'Contact3', title: '', contactName: '', phone: '', race: '', email: '', customerId: '' },
						result.contacts.push({ id: '', label: `Contact${contactsLen + 1 + index}`, title: '', contactName: '', phone: '', race: '', email: '', customerId: '' })
					}
				}
				this.storeForm = {
					...this.storeForm,
					...result,
					imgUrl: result.imgUrl ? result.imgUrl.split(',').map(item => { return {url: item} }) : [],
					contactNameList: result.contacts,
					// customerType: result.customerType ? result.customerType.split(',').map(item => item) : [],
					restockDay: result.restockDay ? result.restockDay.split(',').map(item => item) : [],
					openBrand: result.openBrand ? result.openBrand.split(',').map(item => item) : [],
					closeBrand: result.closeBrand ? result.closeBrand.split(',').map(item => item) : []
				}
				console.log('this.storeForm', this.storeForm)
      }).catch(() => {
        Toast.clear()
      })
		},
		afterRead (files, detail, key) {
      console.log('afterRead-files', files)
      console.log('afterRead-detail', detail)
      console.log('afterRead-key', key)
      const form = new FormData()
      form.append('file', files.file)
			Toast.loading({
        message: 'loading...',
        forbidClick: true,
        duration: 0
      })
      commonFileUploadApi(form).then(res => {
				Toast.clear()
        console.log('commonFileUploadApi', res)
        this.storeForm.imgUrl[detail.index] = {
          ...this.storeForm.imgUrl[detail.index],
          ...res.data,
        }
        files.status = null
      }).catch(() => {
				Toast.clear()
        files.status = 'fail'
      })
    },
		getAddress() {
			const query = {
				longitude: this.storeForm.lng || '',
				latitude: this.storeForm.lat || ''
			}
			this.$router.push({
        path: `/chooseLocation`,
				query: query
      })
		},
		getDetailMap() {
			dictDetailMapApi('closeBrand,openBrand,customerType').then(res => {
				// console.log('dictDetailMapApi', res.data.customerType)
				this.storeTypeList = res.data.customerType
				this.openBrandList = res.data.openBrand
				this.closeBrandList = res.data.closeBrand
			})
		},
		onSubmit() {
			console.log('onSubmit')
		},
		goBack () {
			this.$router.replace(`/visitChannelDetails?art_id=${this.paramsUrl.art_id}&projectId=${this.paramsUrl.projectId}&tabIndex=${this.tabsActive}`)
			this.$router.go(-1)
		}
	}
}
</script>

<style lang="less" scoped>
.create-store {
	font-size: 12px;
	padding-bottom: 50px;
	.nav-bar {
    margin-top: 46px;
  }
	.edit {
    color: #fff;
		background-color: #007bff;
		border: none;
  }
  .form-content {
		margin-top: 56px;
	}
	/deep/.van-cell {
		padding: 10px;
		.van-field__label {
			width: 220px;
		}
		input {
			border: 1px solid #b9cbed;
			padding-left: 5px;
		}
	}
	.area-position {

		/deep/.van-field__body {
			input {
				border: none;
			}
			border: 1px solid #b9cbed;
		}
		/deep/.van-field__control {
			padding-left: 18px;
		}
	}
	.choice-content,
	.basin-info {
		/deep/.van-cell {
			.van-field__label {
				width: 120px;
			}
		}
		/deep/.van-field__control {
			height: 26px;
		}
		.icon-loc-img {
			position: absolute;
			left: 0;
			top: 3px;
      width: 20px;
			height: 20px;
		}
	}
	/deep/.van-field {
		font-size: 12px;
	}
	// /deep/.van-field__value {
	// 	border-bottom: 1px solid #b9cbed;
	// }
	/deep/.van-cell::after {
		border-bottom: 1px solid #b9cbed;
	}
	/deep/.van-checkbox-group {
		.van-checkbox {
			display: flex;
			align-items: center;
		}
		.van-icon {
			width: 16px;
			height: 16px;
			font-size: 12px;
		}
	}
	/deep/.van-radio-group {
		.van-icon {
			width: 18px;
			height: 18px;
			font-size: 12px;
		}
	}
	/deep/.van-cell--required::before {
		left: 5px;
	}
	.add-contact {
		margin: 5px 0;
		margin-left: 10px;
	}
	.contact-content {
		.contact-item {
			display: flex;
			align-items: center;
			height: 35px;
			padding: 10px;
			.line {
				width: 5px;
				height: 15px;
				border-radius: 5px;
				background-color: #007bff;
				margin-right: 7px;
			}
		}
		/deep/.van-cell {
			padding: 10px 20px;
		}
	}
	.btn-control {
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: center;
    justify-content: space-between;
    width: 100%;
  }
}
</style>